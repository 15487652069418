$(document).ready(function() {
  console.log("console loaded");
  $('#select-all').click(function() {
    $('input[name="selected_products[]"]').prop('checked', true);
  });
  $('#deselect-all').click(function() {
    $('input[name="selected_products[]"]').prop('checked', false);
  });
  $('#bulk-action-select').change(function() {
    var selectedAction;
    selectedAction = $(this).val();
    $('#move-confirmation').hide();
    $('#print-confirmation').hide();
    switch (selectedAction) {
      case 'move':
        $('#move-confirmation').show();
        break;
      case 'print':
        $('#print-confirmation').show();
        break;
    }
  });
  $('#bulk-action-form').submit(function(event) {
    event.preventDefault();

    const selectedItems = $('input[name="selected_products[]"]:checked').map(function() {
      return this.value;
    }).get();
    const bulkAction = $('#bulk-action-select').val();
    const positionId = $('input[name="position_id"]').val();
    const newPositionId = $('#new-position-select').val();
    const labelId = $('#label-select').val();

    fetch('/positions/' + positionId + '/bulk_action', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          selected_products: selectedItems,
          new_position_id: newPositionId,
          label_id: labelId,
          bulk_action: bulkAction
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        if (bulkAction === "print") {
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.target = "_blank";
          // downloadLink.download = '';
          downloadLink.click();
        }
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
      });
  });
});
