
//= require jquery
//= require jquery_ujs
//= require jquery-ui
//= require best_in_place
//= require best_in_place.purr
//= require twitter/bootstrap
//= require jquery_nested_form
//= require fancybox
//= require autocomplete-rails
//= require selectize
//= require bootstrap-select
//= require chosen-jquery
//= require chartkick
// require_directory .

//require("offline.min.js")
//import "offline.min.js"



$(document).on("turbolinks:load", function () {
    $.datepicker.regional['cs'] = {
        closeText: 'Zavřít',
        prevText: '&#x3c;Dříve',
        nextText: 'Později&#x3e;',
        currentText: 'Nyní',
        monthNames: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen',
            'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
        monthNamesShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer',
            'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
        dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
        dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
        dayNamesMin: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
        weekHeader: 'Týd',
        dateFormat: 'dd.mm.yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['cs']);
});

$(document).on("turbolinks:load", function () {
    var dates = $("#date_from, #date_to").datepicker({
        dateFormat: "yy-mm-dd",
        onSelect: function (selectedDate) {
            var option = this.id == "date_from" ? "minDate" : "maxDate",
                instance = $(this).data("datepicker");
            date = $.datepicker.parseDate(
                instance.settings.dateFormat ||
                $.datepicker._defaults.dateFormat,
                selectedDate, instance.settings);
            dates.not(this).datepicker("option", option, date);
        }
    });

    $('.toggle_all_checkbox_in_table').change(function () {

        var jq = $(this);
        var inputs = jq.parents('table').find('input[type=checkbox].count_checkbox_in_table');
        inputs.prop('checked', jq.is(':checked'));
        $('#count').text(($('input[type=checkbox]:checked.count_checkbox_in_table').length).toString() + "/" + inputs.length.toString())
    });
    $('.count_checkbox_in_table').change(function () {
        $('#count').text($('input[type=checkbox]:checked.count_checkbox_in_table').length.toString() + "/" + $('input[type=checkbox].count_checkbox_in_table').length.toString())
    });
});
