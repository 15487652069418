import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import Turbolinks from "turbolinks"
//import "channels"
import "trix"
import "@rails/actiontext"
require("trix/dist/trix.umd.min")
require("@rails/actiontext")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

//require("@rails/ujs").start()
//require("@rails/activestorage").start()
//require("channels")
//require("bootstrap")
import 'flag-icon-css/css/flag-icon.css'
import "chartkick/chart.js"
import "../stylesheets/application"
//import '../stylesheets/active_admin'
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/autocomplete';
import "jquery-ui/themes/base/all"
import "bootstrap/dist/js/bootstrap.min"
import "fancybox/dist/js/jquery.fancybox.pack"
import "fancybox/dist/scss/jquery.fancybox"
import "chosen-js/chosen.jquery.min"
import "chosen-js/chosen"
import "bootstrap-select/dist/js/bootstrap-select.min"
import "selectize/dist/js/standalone/selectize.min"
import "autosize/dist/autosize.min"
import "jquery-sparkline/jquery.sparkline.min"
import "fontawesome-free/css/all"
import "fontawesome-free/css/v4-shims"
document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
})

var ClipboardJS = require('clipboard')
$(document).on("turbolinks:load", function() {
  console.log("DEBUG:: turbolinks.load!")
  var clipboard = new ClipboardJS('.btn-clipboard');
  var hideTooltip, setTooltip;
    setTooltip = function(btn, message) {
      $(btn).tooltip('show').attr('data-original-title', message).tooltip('show');
    };
    hideTooltip = function(btn) {
      setTimeout((function() {
        $(btn).tooltip('hide');
      }), 1000);
    };
    $('.clipboard-btn').tooltip({
      trigger: 'click',
      placement: 'bottom'
    });
    clipboard.on('success', function(e) {
      setTooltip(e.trigger, 'Copied!');
      hideTooltip(e.trigger);
    });
    clipboard.on('error', function(e) {
      setTooltip(e.trigger, 'Failed!');
      hideTooltip(e.trigger);
    });
//  console.log(clipboard);
});

$(document).on('turbolinks:load', function(){ $.rails.refreshCSRFTokens(); });

//require("../stylesheets/application.scss")
require("../stylesheets/jquery-ui-timepicker-addon.min")
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../js/', true, /\.js$/));
requireAll(require.context('../coffee/', true, /\.coffee$/));
requireAll(require.context('../stylesheets/', false, /\.css$/));
requireAll(require.context('../stylesheets/', false, /\.scss$/));
//require("../js/site.js")
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

//function requireAll(r) { r.keys().forEach(r); }
//requireAll(require.context('../coffee/', true, /\.coffee$/));
//requireAll(require.context('../js/', true, /\.js$/));
//import "bootstrap-sass";
//require("channels")
//import '~bootstrap-2.3.2/js/bootstrap-tooltip';
//requireAll(require.context('../../../node_modules/bootstrap-2.3.2/js/', false, /\.js$/));
//import "chartkick/chart.js"
//import "bootstrap"
//require("chartkick")
//require("chart.js")

$(document).on("turbolinks:load", function() {
  $(function () {
    console.log('Hello World from Webpacker');
    $('.selectpicker').selectpicker();
  });
});

